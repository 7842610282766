var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Listagem de Certidões de Débito"),
      ]),
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", dense: "", text: "", type: "success" },
          model: {
            value: _vm.certidaoExcluidaSucess,
            callback: function ($$v) {
              _vm.certidaoExcluidaSucess = $$v
            },
            expression: "certidaoExcluidaSucess",
          },
        },
        [_vm._v("Certidão excluida com sucesso.")]
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "12" },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              _vm.isAdmin
                                ? {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: "",
                                                  disabled: _vm.certidoes == [],
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openModal()
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-plus-box"),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Adicionar certidão")])]
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            left: "",
                            "close-on-content-click": false,
                            "accept-charset": "UTF-8",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { icon: "" } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            content: _vm.qtdFilter,
                                            value: _vm.qtdFilter,
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-filter-variant"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { width: "350" } },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "py-1" },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "static",
                                    attrs: {
                                      label: "CNPJ",
                                      items: _vm.establishmentsParsed,
                                      "append-icon": "mdi-chevron-down",
                                      "item-text": "cnpjFilial",
                                      "item-value": (item) =>
                                        `${item.cnpjFilial}`,
                                      clearable: "",
                                      dense: "",
                                      "hide-details": "",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                      multiple: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function ({ index }) {
                                          return [
                                            index === 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("cnpj")(
                                                          _vm.filter.cnpj.length
                                                        )
                                                      ) +
                                                      " selecionado "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item",
                                        fn: function ({ item, attrs, on }) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: {
                                                      "pl-10": item.group,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            active,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-list-item-action",
                                                                [
                                                                  _c(
                                                                    "v-checkbox",
                                                                    {
                                                                      attrs: {
                                                                        "input-value":
                                                                          active,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "cnpj"
                                                                          )(
                                                                            item.cnpjFilial
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.socialName
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  "v-list-item",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.filter.cnpj,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filter, "cnpj", $$v)
                                      },
                                      expression: "filter.cnpj",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                { staticClass: "py-3" },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "static",
                                    attrs: {
                                      label: "Tipo",
                                      items: _vm.tipos,
                                      "append-icon": "mdi-chevron-down",
                                      "item-value": (item) => `${item}`,
                                      multiple: false,
                                      dense: "",
                                      "hide-details": "",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                    },
                                    model: {
                                      value: _vm.filter.certificate_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "certificate_type",
                                          $$v
                                        )
                                      },
                                      expression: "filter.certificate_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'",
                                      },
                                    ],
                                    attrs: {
                                      maxlength: "10",
                                      minlength: "10",
                                      label: "Data de emissão",
                                      dense: "",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                    },
                                    model: {
                                      value: _vm.filter.time_issuance,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "time_issuance",
                                          $$v
                                        )
                                      },
                                      expression: "filter.time_issuance",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'",
                                      },
                                    ],
                                    attrs: {
                                      maxlength: "10",
                                      minlength: "10",
                                      label: "Validade",
                                      dense: "",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                    },
                                    model: {
                                      value: _vm.filter.expiration_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "expiration_date",
                                          $$v
                                        )
                                      },
                                      expression: "filter.expiration_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Código de controle",
                                      dense: "",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                      maxlength: "19",
                                      minlength: "19",
                                    },
                                    on: { input: _vm.fetchDebounced },
                                    model: {
                                      value: _vm.filter.code_control,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "code_control",
                                          $$v
                                        )
                                      },
                                      expression: "filter.code_control",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      items: _vm.pendencias,
                                      label: "Pendências",
                                      outlined: "",
                                      dense: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("pendency")(data.item)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "selection",
                                        fn: function (item) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("pendency")(item.item)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.filter.pendencyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "pendencyType",
                                          $$v
                                        )
                                      },
                                      expression: "filter.pendencyType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-btn",
                                        { on: { click: _vm.limparFiltro } },
                                        [_vm._v(" Limpar ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "success",
                                        loading: _vm.loadingSave,
                                        disabled: _vm.loadingSave,
                                      },
                                      on: { click: _vm.acao },
                                    },
                                    [_vm._v(" Filtrar ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.certidoes,
              "items-per-page": 10,
              options: _vm.options,
              loading: _vm.myloadingvariable,
            },
            on: {
              "update:options": function ($event) {
                _vm.options = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.cnpj",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " ")]
                },
              },
              {
                key: "item.expiration_date",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("date")(item.expiration_date)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.time_issuance",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("date")(item.time_issuance)) + " "
                    ),
                  ]
                },
              },
              {
                key: "item.pendencyType",
                fn: function ({ item }) {
                  return [
                    item.pendencyType === "UNAVAILABLE"
                      ? _c(
                          "span",
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "lock2",
                                attrs: { small: "", id: "btnLock" },
                              },
                              [_vm._v("mdi-lock-outline")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("pendency")(item.pendencyType)) +
                                " "
                            ),
                            _c("br"),
                            _c("p", { staticClass: "font-weight-light" }, [
                              _vm._v("Altere seu plano"),
                            ]),
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(_vm._s(_vm._f("pendency")(item.pendencyType))),
                        ]),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "AGTooltip",
                      { attrs: { "tooltip-text": "Histórico" } },
                      [
                        _c(
                          "v-btn",
                          { attrs: { id: "btnhistory", icon: "", small: "" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "text-button",
                                staticStyle: { "text-decoration": "none" },
                                attrs: {
                                  to: `/certidoes/historico/${item.id_certificate}`,
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(" mdi-history"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "AGTooltip",
                      { attrs: { "tooltip-text": "Editar" } },
                      [
                        _vm.isAdmin
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "button-size",
                                attrs: {
                                  id: "btnEdit",
                                  color: "primary",
                                  icon: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editModal(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "AGTooltip",
                      { attrs: { "tooltip-text": "Excluir" } },
                      [
                        _vm.isAdmin
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "button-size",
                                attrs: {
                                  id: "btnDelete",
                                  color: "primary",
                                  icon: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmarExlusaoCertidao(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-delete ")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "330" },
              model: {
                value: _vm.dialogExluir,
                callback: function ($$v) {
                  _vm.dialogExluir = $$v
                },
                expression: "dialogExluir",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(" Deseja realmente excluir a certidão? "),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "red darken-1",
                            id: "btnCancelDelete",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.cancelaExclusaoCertidao()
                            },
                          },
                        },
                        [_vm._v(" Não ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "blue darken-1",
                            id: "btnConfirmDelete",
                            text: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.excluirCertidao()
                            },
                          },
                        },
                        [_vm._v(" Sim ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-certidao-nova", {
        ref: "modalCertidaoNova",
        attrs: { certidao: _vm.certidaoEdit },
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }