<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Listagem de Certidões de Débito</h2>

    <v-alert v-model="certidaoExcluidaSucess" dismissible dense text type="success"
      >Certidão excluida com sucesso.</v-alert
    >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" class="text-right">
            <v-tooltip bottom>
              <template v-if="isAdmin" v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-2" v-bind="attrs" @click="openModal()" v-on="on" :disabled="certidoes == []">
                  <v-icon>mdi-plus-box</v-icon>
                </v-btn>
              </template>
              <span>Adicionar certidão</span>
            </v-tooltip>
            <v-menu offset-y left :close-on-content-click="false" accept-charset="UTF-8">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-badge :content="qtdFilter" :value="qtdFilter">
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <v-list width="350">
                <v-list-item class="py-1">
                  <v-autocomplete
                  v-model="filter.cnpj"
                  label="CNPJ"
                  :items="establishmentsParsed"
                  append-icon="mdi-chevron-down"
                  item-text="cnpjFilial"
                  :item-value="(item) => `${item.cnpjFilial}`"
                  clearable
                  dense
                  hide-details
                  outlined
                  persistent-placeholder
                  class="static"
                  multiple            
              >
                    <template v-slot:selection="{ index }">
                      <span v-if="index === 0">
                        {{ filter.cnpj.length | cnpj }} selecionado
                      </span>
                    </template>
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item #default="{ active }" v-bind="attrs" :class="{ 'pl-10': item.group }" v-on="on">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.cnpjFilial | cnpj }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ item.socialName }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-list-item>
                <v-list-item class="py-3">
                  <v-autocomplete
                    v-model="filter.certificate_type"
                    label="Tipo"
                    :items="tipos"
                    append-icon="mdi-chevron-down"
                    :item-value="(item) => `${item}`"
                    :multiple="false"
                    dense
                    hide-details
                    outlined
                    persistent-placeholder
                    class="static"
                  >
                  </v-autocomplete>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="filter.time_issuance"
                    v-mask="'##/##/####'"
                    maxlength="10"
                    minlength="10"
                    label="Data de emissão"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-list-item>

                <v-list-item>
                  <v-text-field
                    v-model="filter.expiration_date"
                    v-mask="'##/##/####'"
                    maxlength="10"
                    minlength="10"
                    label="Validade"
                    dense
                    outlined
                    persistent-placeholder
                  />
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="filter.code_control"
                    label="Código de controle"
                    dense
                    outlined
                    persistent-placeholder
                    @input="fetchDebounced"
                    maxlength="19"
                    minlength="19"
                  />
                </v-list-item>
                <v-list-item>
                  <v-combobox v-model="filter.pendencyType" :items="pendencias" label="Pendências" outlined dense>
                    <template slot="item" slot-scope="data">
                      {{ data.item | pendency }}
                    </template>
                    <template slot="selection" slot-scope="item">
                      {{ item.item | pendency }}
                    </template></v-combobox
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item><v-btn @click="limparFiltro"> Limpar </v-btn></v-list-item>
                  <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="acao"> Filtrar </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="certidoes"
        :items-per-page="10"
        :options.sync="options"
        :loading="myloadingvariable"
      >
        <template v-slot:item.cnpj="{ item }">
          {{ item.cnpj | cnpj }}
        </template>
        <template v-slot:item.expiration_date="{ item }">
          {{ item.expiration_date | date }}
        </template>
        <template v-slot:item.time_issuance="{ item }">
          {{ item.time_issuance | date }}
        </template>
        <template v-slot:item.pendencyType="{ item }"
          ><span v-if="item.pendencyType === 'UNAVAILABLE'">
            <!-- <v-btn icon x-small><v-icon class="lock">mdi-lock-outline</v-icon> </v-btn> -->
            <v-icon small class="lock2" id="btnLock">mdi-lock-outline</v-icon>
            {{ item.pendencyType | pendency }}
            <br />
            <p class="font-weight-light">Altere seu plano</p></span
          >
          <span v-else>{{ item.pendencyType | pendency }}</span>
        </template>
        <!-- <template v-slot:item.hour_issuance="{ item }">
          {{ item.hour_issuance | date({ mask: 'HH:mm:ss' }) }}
        </template> -->

        <template v-slot:item.actions="{ item }">
          <AGTooltip tooltip-text="Histórico">
            <v-btn id="btnhistory" icon small>
              <router-link
                style="text-decoration: none"
                class="text-button"
                :to="`/certidoes/historico/${item.id_certificate}`"
                ><v-icon small class=""> mdi-history</v-icon>
              </router-link>
            </v-btn>
          </AGTooltip>
          <AGTooltip tooltip-text="Editar">
            <v-btn v-if="isAdmin" id="btnEdit" color="primary" icon class="button-size" @click="editModal(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </AGTooltip>
          <AGTooltip tooltip-text="Excluir">
            <v-btn
              v-if="isAdmin"
              id="btnDelete"
              color="primary"
              icon
              class="button-size"
              @click="confirmarExlusaoCertidao(item)"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </AGTooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="dialogExluir" persistent max-width="330">
        <v-card>
          <v-card-title class="text-h5"> Deseja realmente excluir a certidão? </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" id="btnCancelDelete" text @click="cancelaExclusaoCertidao()"> Não </v-btn>
            <v-btn color="blue darken-1" id="btnConfirmDelete" text @click="excluirCertidao()"> Sim </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <modal-certidao-nova ref="modalCertidaoNova" :certidao="certidaoEdit" @refresh="refresh" />
  </v-main>
</template>

<script>
import certidaoService from '@/services/certidaoService.js';
import eSocialService from '@/services/eSocialService.js';
import gedService from '@/services/gedService.js';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import { mapGetters } from 'vuex';

export default {
  name: 'Certidoes',
  components: {
    ModalCertidaoNova: () => import('./components/ModalCertidaoNova.vue'),
    AGTooltip: () => import('../commons/AGTooltip.vue'),
  },

  data() {
    return {
      idCertidaoExcluir: null,
      dialogExluir: false,
      myloadingvariable: false,
      filter: {
        name_establishment: null,
        cnpj: null,
        certificate_type: null,
        code_control: null,
        time_issuance: null,
        hour_issuance: null,
        expiration_date: null,
        pendencyType: null,
      },

      isEdit: false,
      certidaoEdit: {},
      options: {},
      certidoes: [],
      certidaoResponse: [],
      totalItems: 0,
      headers: [
        { text: 'Empresa', align: 'start', value: 'name_establishment', width: '150px' },
        { text: 'CNPJ', align: 'start', value: 'cnpj' },
        { text: 'Tipo', value: 'certificate_type' },
        { text: 'Data da emissão', value: 'time_issuance' },
        { text: 'Hora da emissão', value: 'hour_issuance' },
        { text: 'Validade', value: 'expiration_date' },
        { text: 'Código de controle', value: 'code_control' },
        { text: 'Pendências', value: 'pendencyType', align: 'start' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      //date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      certidaoExcluidaSucess: false,
      loadingSave: false,
      userEstablishment: [],
      tipos: ['CND', 'CPEN', 'Indisponível'],
      //SOLICITADO NA ESTÓRIA AG-272
      //pendencias: ['TRUE', 'FALSE', 'UNAVAILABLE'],
      pendencias: ['TRUE', 'FALSE'],
      mensagemFilter: null,
      isMensagemFilter: false,
      isFilter: false,
      isAdmin: false,
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      perfis: 'user/userAuthorities',
    }),

    computedDateFormatted() {
      return this.formatDate(this.filter.time_issuance);
    },

    qtdFilter() {
      let qtd = 0;

      // eslint-disable-next-line no-unused-vars
      Object.entries(this.filter).forEach(([key, value]) => {
        if (value && value!="") qtd++;
      });

      return qtd;
    },

    establishmentsParsed() {
      let items = [];
      let groupMatriz = groupBy(this.userEstablishment, 'company');
      groupMatriz = Object.entries(groupMatriz).map(([key, value]) => ({
        company: key,
        establishments: value,
      }));
      groupMatriz = sortBy(groupMatriz, ['company']);
      groupMatriz.forEach((item, index) => {
        if (index > 0) items.push({ divider: true });
        const group = item.company;
        items.push({ header: group });
        item.establishments.forEach((innerItem) => {
          innerItem.group = group;
          items.push(innerItem);
        });
      });

      return items;
    },
  },

  watch: {
    options: {
      handler() {
        if (this.isFilter) this.acao();
        else this.fetch();
      },
    },
  },

  created() {
    this.getCompanies();
    this.fetchDebounced = debounce(this.fetch, 300);
  },

  methods: {
    
    async getCompanies() {
      let adminOrNot = this.perfis.filter((n) => n === 'ROLE_ADMIN');

      if (adminOrNot.length > 0) {
        this.isAdmin = true;
      }
      let { data } = await eSocialService.establishment.getByUserId();

      let companies = data.filter((v, i, a) => a.findIndex((t) => t.cnpjMatriz === v.cnpjMatriz) === i);
      companies = sortBy(companies, ['socialName', 'stateCode', 'city']);
      let establishments = data.filter((v, i, a) => a.findIndex((t) => t.cnpjFilial === v.cnpjFilial) === i);
      establishments = sortBy(establishments, ['socialName', 'stateCode', 'city']);

      this.userEstablishment = establishments;
    },

    async fetch() {
      this.myloadingvariable = true;
      let response = await certidaoService.certidoes.get();
      this.certidaoResponse = response.data;
      let listado = [];
      let keys = [];

      Object.entries(this.certidaoResponse).forEach(([key, entries]) => {
        keys.push(key);
        listado.push({
          name_establishment: entries.name_establishment,
          cnpj: entries.cnpj,
          certificate_type: entries.certificate_type,
          time_issuance: entries.time_issuance != null ? entries.time_issuance.substring(0, 10) : null,
          hour_issuance: entries.time_issuance != null && entries.certificate_type != "Indisponível" ? entries.time_issuance.substring(11, 19) : null,
          expiration_date: entries.expiration_date != null ? entries.expiration_date.substring(0, 10) : null,
          code_control: entries.code_control,
          pendencyType: entries.pendencyType,
          id_certificate: entries.id_certificate,
          id_cnd_file: entries.id_cnd_file,
          id_pendency_file: entries.id_pendency_file,
        });
      });

      this.certidoes = listado;
      this.totalItems = listado.length;
      this.myloadingvariable = false;
    },

    formatDate(item) {
      //item = item.replaceAll('-', '/');
      let dateArray = item.split('-');
      //let d1 = new Date(dateArray[2], dateArray[1], dateArray[0]);
      return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    },

    confirmarExlusaoCertidao(certidao) {
      this.idCertidaoExcluir = certidao.id_certificate;
      this.dialogExluir = true;
    },

    cancelaExclusaoCertidao() {
      this.idCertidaoExcluir = null;
      this.dialogExluir = false;
    },

    async excluirCertidao() {
      this.dialogExluir = false;
      await certidaoService.certidoes.delete(this.idCertidaoExcluir);

      this.idCertidaoExcluir = null;
      this.certidaoExcluidaSucess = true;
      this.fetch();
    },

    async downloadCertidao(certidao) {
      let responseGed = '';
      let fileName = '';
      if (certidao.id_cnd_file) {
        fileName = 'certidao.pdf';
        responseGed = await gedService.files.get(certidao.id_cnd_file);
      } else {
        fileName = 'pendencia.pdf';
        responseGed = await gedService.files.get(certidao.id_pendency_file);
      }
      this.downloadDocument(responseGed, fileName);
    },

    downloadDocument(responseGed, fileName) {
      const linkSource = 'data:application/pdf;base64,' + responseGed.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    async acao() {
      let certidao = {};
      this.mensagemFilter = '';
      this.isMensagemFilter = false;

      if (
        this.filter.time_issuance != '' &&
        this.filter.time_issuance != null &&
        this.filter.hour_issuance == '' &&
        this.filter.hour_issuance == null
      ) {
        this.mensagemFilter = 'Favor informe o horario';
        this.isMensagemFilter = true;
        return;
      }
      if (
        this.filter.time_issuance == '' &&
        this.filter.time_issuance == null &&
        this.filter.hour_issuance != '' &&
        this.filter.hour_issuance != null
      ) {
        this.mensagemFilter = 'Favor informe o horario';
        this.isMensagemFilter = true;
        return;
      }

      if (this.filter.time_issuance != '' && this.filter.time_issuance != null) {
        certidao.timeIssuance = this.formatarDateUS(this.filter.time_issuance);
      }

      if (this.filter.hour_issuance != '' && this.filter.hour_issuance != null) {
        certidao.hourIssuance = this.filter.hour_issuance;
      }

      if (this.filter.expiration_date != null && this.filter.expiration_date != '') {
        certidao.expirationDate = this.formatarDateUS(this.filter.expiration_date);
      }
      
      if (this.filter.certificate_type != null && this.filter.certificate_type != '') {
        certidao.certificateType = this.filter.certificate_type;
      }

      if (this.filter.code_control != null && this.filter.code_control != '') {
        certidao.codeControl = this.filter.code_control;
      }

      if (this.filter.pendencyType != null && this.filter.pendencyType != '') {
        certidao.pendencyType = this.filter.pendencyType;
      }

      if (this.filter.cnpj != null && this.filter.cnpj != '') {
          certidao.cnpj = [];

          this.filter.cnpj.forEach((item) => {
              if (typeof item === 'string') {
                  certidao.cnpj.push(item);
              }
          });
      }

      this.loadingSave = true;

      let responseFilter = await certidaoService.certidoes.filter(certidao);

      if (responseFilter.data.length > 0) {
        this.isFilter = true;
        this.certidaoResponse = responseFilter.data;
        let listado = [];
        let keys = [];
        Object.entries(this.certidaoResponse).forEach(([key, entries]) => {
          keys.push(key);
          listado.push({
            name_establishment: entries.name_establishment,
            cnpj: entries.cnpj,
            certificate_type: entries.certificate_type,
            time_issuance: entries.time_issuance != null ? entries.time_issuance.substring(0, 10) : null,
            hour_issuance: entries.time_issuance != null ? entries.time_issuance.substring(11, 19) : null,
            expiration_date: entries.expiration_date != null ? entries.expiration_date.substring(0, 10) : null,
            code_control: entries.code_control,
            pendencyType: entries.pendencyType,
            id_certificate: entries.id_certificate,
            id_cnd_file: entries.id_cnd_file,
            id_pendency_file: entries.id_pendency_file,
          });
        });

        this.certidoes = listado;
      } else {
        this.certidoes = [];
      }
      this.loadingSave = false;
    },

    formatarDateUS(date) {
      const [dia, mes, ano] = date.split('/');
      return ano + '-' + mes + '-' + dia;
    },

    formatarDateUSExpiration(date) {
      const [dia, mes, ano] = date.split('/');
      return ano + '-' + mes + '-' + dia + 'T00:00:00.000';
    },

    openModal() {
      this.$refs.modalCertidaoNova.$emit('open');
    },

    editModal(item) {
      if (item.expiration_date) {
        item.expiration_date = this.formatDate(item.expiration_date);
      }
      if (item.time_issuance) {
        item.time_issuance = this.formatDate(item.time_issuance);
      }
      if (item) {
        this.certidaoEdit = item;
        this.$refs.modalCertidaoNova.$emit('edit');
      }
    },

    formatarData(data) {
      let mesFormat = data.getMonth() + 1;
      let mes = mesFormat < 10 ? '0' + mesFormat : mesFormat;
      let dia = data.getDate() < 10 ? '0' + data.getDate() : data.getDate();
      return dia + '/' + mes + '/' + data.getFullYear();
    },

    obterHorasDate(date) {
      let data = new Date(date);
      let horas = data.getHours() < 10 ? '0' + data.getHours() : data.getHours();
      let minutos = data.getMinutes() < 10 ? '0' + data.getMinutes() : data.getMinutes();
      let segundos = data.getSeconds() < 10 ? '0' + data.getSeconds() : data.getSeconds();

      return horas + ':' + minutos + ':' + segundos;
    },

    limparFiltro() {
      this.isFilter = false;
      this.filter = {};
      this.fetch();
    },

    refresh() {
      this.certidaoEdit = {};
      this.fetch();
    },
  },
};
</script>
<style scoped>
.font-weight-light {
  margin-top: -10px !important;
  margin-left: 30px;
  font-size: 12px;
}

/* .lock {
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
} */
.lock2 {
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.button-size >>> .v-icon {
  font-size: 16px !important;
}
</style>
